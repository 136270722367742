import { template as template_3dd7b5e4a6a943969728fe3dd959ac01 } from "@ember/template-compiler";
const WelcomeHeader = template_3dd7b5e4a6a943969728fe3dd959ac01(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
