import { template as template_d4c070f44ef245139cf5f846bce1b9d1 } from "@ember/template-compiler";
const FKLabel = template_d4c070f44ef245139cf5f846bce1b9d1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
