import { template as template_bc9c0d10450449558fdfa0e5975f0190 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bc9c0d10450449558fdfa0e5975f0190(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
