import { template as template_a6ffeaf2b36b4990b72ea48937fad912 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a6ffeaf2b36b4990b72ea48937fad912(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
